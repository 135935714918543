import store from "../store"
import {
    authenticated
} from "../settings/AuthApi"

/**check authorization steps */
function authorizeStep1Guard(to, from, next) {
    if (!store.state.authenticated) {
        next({ path: '/login', replace: true })
        return
    }

    if (!store.state.at.step1) {
        next()
        return
    }

    if (!store.state.at.step2) {
        next({
            path: '/authorize/step-2',
            replace: true
        })
        return
    }

    next({
        path: '/',
        replace: true
    })
}

/**check authorization steps */
function authorizeStep2Guard(to, from, next) {
    if (!store.state.authenticated) {
        next({ path: '/login', replace: true })
        return
    }

    if (!store.state.at.step1) {
        next({
            path: '/authorize',
            replace: true
        })
        return
    }

    if (!store.state.at.step2) {
        next()
        return
    }

    next({
        path: '/',
        replace: true
    })
}

/**show verify or not */
function VerifyGuard(to, from, next) {
    /**check if we have authenticated */
    if (authenticated()) return next({
        path: '/',
        replace: true
    })

    /**we are not authenticated
     * check mobile number filled or not
     */
    if (!store.state.mobile) {
        next({
            path: 'login',
            replace: true
        })
    } else {
        next()
    }
}

/**redirect if authenticated */
function RedirectIfAuthenticated(to, from, next) {
    if (authenticated()) {
        next({
            path: "/dashboard",
            raplace: true
        })
        return
    }
    next()
}

/**  Redirect If Not Authenticated */
function RedirectIfNotAuthenticated(to, from, next) {
    if (!authenticated()) {
        next({
            path: "/",
            raplace: true
        })
        return
    }
    next()
}

/** clear profile for open user-detail */
function clearProfile(to, from, next) {
    store.commit('at/setFirstName', null)
    next()
}

/**export the guard */
export {
    authorizeStep1Guard,
    authorizeStep2Guard,
    VerifyGuard,
    RedirectIfAuthenticated,
    RedirectIfNotAuthenticated,
    clearProfile
}