var settings = {
    apiV1: window.env.VUE_APP_API_END_POINT,
    translate: {
        error401: "توکن ورود شما منقضی شده است ، لطفا مجددا وارد سایت شوید",
        error403: "جلسه کاری شما به اتمام رسیده است",
        error404: "متاسفانه صفحه مورد نظر یافت نشد",
        error500: "خطایی از سمت سرور رخ داده است ، لطفا بعدا مجددا تلاش نمایید",
        error502: "Bad Gateway Error Occured!",
        errorUnknown: "یک ارور ناشناخته رخ داده است"
    }
}

export { settings }