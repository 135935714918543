<template>
  <div>
    <div v-if="updateExists" class="worker-update-exists-dialog">
      <div class="d-flex p-2">نسخه جدید از وبسایت موجود است!</div>
      <div class="d-flex">
        <base-button class="mx-2" type="danger" @click="(updateExists=false)"> فعلا نه </base-button>
        <base-button type="success" @click="refreshApp()"> بروزرسانی</base-button>
      </div>
    </div>
    <router-view :key="$route.fullPath"></router-view>
    <notifications :ignoreDuplicates="false" position="bottom left" :duration="5000" />
    <notifications group="copynotif" position="bottom left" :duration="100" />
    <nprogress-container></nprogress-container>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
/**nprogress */
import NprogressContainer from "vue-nprogress/src/NprogressContainer"

import Vue from "vue"
/**import modal */
import VModal from 'vue-js-modal/dist/index.nocss.js'
Vue.use(VModal)

/**service worker mixin */
import updateMixin from "@/settings/update"

export default {
  mixins: [updateMixin],
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    },
  },
  mounted() {
    this.$watch('$route', this.disableRTL, { immediate: true });
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
  },
  components: {
    NprogressContainer
  }
}
</script>

<style lang="scss">
.worker-update-exists-dialog {
  z-index: 2;
  position: fixed;
  width: 230px;
  height: 100px;
  background: rgb(82, 82, 82);
  padding: 10px 10px;
  border-radius: 20px 0 20px 20px;
  color: white;
}
</style>
