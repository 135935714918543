import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/** axios for ajax calls */
import { axiosApiInstance } from "../settings/Axios"

/**auth api's */
import { removeToken, setToken, getToken, getRefreshToken, setRefreshToken } from "../settings/AuthApi"

/**the router */
import router from "../router"

const auth = {
  namespaced: true,
  state: {
    /**check if authenticated or not */
    authenticated: false,

    jwtToken: null,
    refreshTokenCode: null, // for reGet jwtToken

    /**profile */
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    nid: null, // nationality
    birthDate: null,
    zipCode: null,
    uuid: null,
    avatar: null,
    codeMeli: null,
    picCodeMeli: null,
    stateCodeMeli: null,
    picAcceptance: null,
    stateAcceptance: null,
    registerDetail: {
      time: null,
      ip: null,
      countIp: null
    },
    summaryState: null,
    representative: null,
    representativeCode:null,
    systemMessage: null,

    /** enum ReCaptcah or ArCaptcha */
    captchaType: null,
    recaptchaLoaded: false,
    recaptchaLoadedFailed: false

  },
  mutations: {
    setRecaptchaLoaded(state, payload) {
      state.recaptchaLoaded = payload
    },
    setFailedRecaptchaLoaded(state, payload) {
      state.recaptchaLoadedFailed = payload
    },
    /** set type captcha (enum ReCaptcah or ArCaptcha) */
    setCaptchaType(state, payload) {
      state.captchaType = payload;
    },

    /**set mobile number */
    setAuthenticated(state, payload) {
      state.authenticated = payload
    },

    /** use for get again jwtToken  */
    setRefreshTokenCode(state, payload) {
      state.refreshTokenCode = payload
    },

    /**  jwtToken  */
    setJwtToken(state, payload) {
      state.jwtToken = payload
    },

    /** start set profile */
    setFirstName(state, payload) {
      state.firstName = payload
    },
    setLastName(state, payload) {
      state.lastName = payload
    },
    setNid(state, payload) {
      state.nid = payload
    },
    setCardNumber(state, payload) {
      state.cardNumber = payload
    },
    setBirthDate(state, payload) {
      state.birthDate = moment(payload, 'YYYY-MM-DD').format('jYYYY/jM/jD')

    },
    setAvatar(state, payload) {
      state.avatar = payload
    },
    setUuid(state, payload) {
      state.uuid = payload
    },
    setZipCode(state, payload) {
      state.zipCode = payload
    },

    setEmail(state, payload) {
      state.email = payload
    },
    setMobile(state, payload) {
      state.mobile = payload
    },
    setCodeMeli(state, payload) {
      state.codeMeli = payload
    },
    setPicCodeMeli(state, payload) {
      state.picCodeMeli = payload

    },
    setStateCodeMeli(state, payload) {
      state.stateCodeMeli = payload
    },
    setPicAcceptance(state, payload) {
      state.picAcceptance = payload
    },
    setStateAcceptance(state, payload) {
      state.stateAcceptance = payload
    },
    setRegisterDetail(state, payload) {
      state.registerDetail = payload
    },
    setSummaryState(state, payload) {
      state.summaryState = payload
    },
    setRepresentative(state, payload) {
      state.representative = payload
    },
    setRepresentativeCode(state, payload) {
      state.representativeCode = payload
    },
    setSystemMessage(state, payload) {
      state.systemMessage = payload
    },


    /** end set profile */
  },
  actions: {
    /**
     * check recaptcha loaded
     */
    loadRecaptcha({ commit }, payload) {
      commit('setCaptchaType', 'ReCaptcha')
      commit("setRecaptchaLoaded", payload)
      /** cheack recaptcha failed */
      if (!payload) commit("setFailedRecaptchaLoaded", true)
    },
    loadArcaptcha({ commit }, payload) {
      commit('setCaptchaType', 'ArCaptcha')
      commit("setRecaptchaLoaded", payload)
    },
    ignoreCaptcha({ commit }, payload) {
      commit('setCaptchaType', 'ignoreCaptcha')
      commit("setRecaptchaLoaded", payload)
    },

    /**
        * get user profile
        * and get the profile steps
        */
    async initProfile({ commit }, id) {
      /**set profile */
      let profile = await axiosApiInstance({
        method: 'get',
        url: 'Organizer/User/GetProfile',
        headers: {
          uIdCode: id
        }
      })

      commit('setProfile', profile.data.data, { root: true })
      commit('at/setFirstName', this.getters.profileGetter(1000110), { root: true })
      commit('at/setAvatar', this.getters.profileGetter(1000109), { root: true })
      commit('at/setLastName', this.getters.profileGetter(1000120), { root: true })
      commit('at/setMobile', this.getters.profileGetter(1000130), { root: true })
      commit('at/setEmail', this.getters.profileGetter(1000140), { root: true })
      commit('at/setUuid', id, { root: true })
      commit('at/setNid', this.getters.profileGetter(1000150), { root: true })// nationality   
      commit('at/setBirthDate', this.getters.profileGetter(1000170), { root: true })
      commit('at/setCodeMeli', this.getters.profileGetter(1000160), { root: true })
      commit('at/setPicCodeMeli', this.getters.profileGetter(1000163), { root: true })
      commit('at/setStateCodeMeli', this.getters.profileGetter(1000167), { root: true })
      commit('at/setPicAcceptance', this.getters.profileGetter(1000180), { root: true })
      commit('at/setStateAcceptance', this.getters.profileGetter(1000187), { root: true })

      commit('at/setSystemMessage', this.getters.profileGetter(1000234), { root: true })
      commit('at/setRepresentative', this.getters.profileGetter(1000213), { root: true })
      commit('at/setRepresentativeCode', this.getters.profileGetter(1000215), { root: true })
      commit('at/setSummaryState', this.getters.profileGetter(1000203), { root: true })
      commit('at/setRegisterDetail', {
        time: this.getters.profileGetter(1000211),
        ip: this.getters.profileGetter(1000217),
        countIp: this.getters.profileGetter(1000219)
      }, { root: true })



      // برای فیلدهای زیر مقداری ارسال نشده هنوز
      // commit('setTotalReferal', profile.data.total_referral)
      // commit('setTotalReward', profile.data.total_reward)
      // commit('at/setZipCode', profile.data.zip_code)
      // commit('setUserBalance', profile.data.balance)



      /**set profile status */
      // let profileStatus = await axiosApiInstance({
      //     url: 'status',
      //     method: 'get'
      // })
      // commit('setProfileStatus', profileStatus.data)
      // dispatch('initAuthorize')

    },

    /**
       * get user profile
       * and get the profile steps
       */
    async getMyProfile({ commit }) {
      /**set profile */
      let profile = await axiosApiInstance({
        url: 'Account/ProfileGet',
        method: 'get'
      })

      commit('setProfile', profile.data.data, { root: true })
      commit('at/setFirstName', this.getters.profileGetter(1000110), { root: true })
      commit('at/setAvatar', this.getters.profileGetter(1000109), { root: true })
      commit('at/setLastName', this.getters.profileGetter(1000120), { root: true })
      commit('at/setMobile', this.getters.profileGetter(1000130), { root: true })
      commit('at/setEmail', this.getters.profileGetter(1000140), { root: true })
      // commit('at/setUuid', id, { root: true })
      commit('at/setNid', this.getters.profileGetter(1000150), { root: true })// nationality   
      commit('at/setBirthDate', this.getters.profileGetter(1000170), { root: true })
      commit('at/setCodeMeli', this.getters.profileGetter(1000160), { root: true })
      commit('at/setPicCodeMeli', this.getters.profileGetter(1000163), { root: true })
      commit('at/setStateCodeMeli', this.getters.profileGetter(1000167), { root: true })
      commit('at/setPicAcceptance', this.getters.profileGetter(1000180), { root: true })
      commit('at/setStateAcceptance', this.getters.profileGetter(1000187), { root: true })

      commit('at/setSystemMessage', this.getters.profileGetter(1000234), { root: true })
      commit('at/setRepresentative', this.getters.profileGetter(1000213), { root: true })
      commit('at/setRepresentativeCode', this.getters.profileGetter(1000215), { root: true })
      commit('at/setSummaryState', this.getters.profileGetter(1000203), { root: true })
      commit('at/setRegisterDetail', {
        time: this.getters.profileGetter(1000211),
        ip: this.getters.profileGetter(1000217),
        countIp: this.getters.profileGetter(1000219)
      }, { root: true })
    },

    /**
     * check user profile state and move user to proper step
     */
    checkUserProfilAndMoveUser() {
      router.push({ path: '/dashboard', replace: true })
    },

    /**logout user */
    async logout({ commit }) {
      try {

        /**move user out */
        commit('setRefreshTokenCode', null, { root: true })

        let token = getToken()
        if (!token)
          return

        await removeToken()

        // request logout
        await axiosApiInstance({
          method: 'get',
          url: "Auth/logout",
          headers: {
            authorization: 'Bearer ' + token
          }
        });

      } catch (error) {
        console.log(error)
      }

      router.push({ path: '/', replace: true })

    },

  },

}
export default new Vuex.Store({
  state: {
    commissionRules: null,
    // array profile befor detect fields
    profile: [{ id: 1, name: 'hassan', title: 'نام' }, { id: 2, name: 'reza', title: 'نام' }],


    //Enums Endpoint (use in directory report/all page-> use for filter [fill multiselect])
    userLogType: [],
    assetLogType: [],

    /** use for get again jwtToken  */
    refreshTokenCode: null,
  },
  mutations: {
    setCommissionRules(state, payload) {
      state.commissionRules = payload
    },
    setProfile(state, payload) {
      state.profile = payload
    },
    setUserLogType(state, payload) {
      state.userLogType = payload
    },
    setAssetLogType(state, payload) {
      state.assetLogType = payload
    },
    /** use for get again jwtToken  */
    setRefreshTokenCode(state, payload) {
      state.refreshTokenCode = payload
    },
  },
  getters: {
    /** get data profile from array
     * @param id detect profile field
     * @return object =>[id:2132,title:'name',value:'hassan']
     */
    profileGetter: (state) => (id) => {
      let filed = state.profile.filter(function (item) {
        return item.id == id
      });
      return filed[0] ? filed[0]['value'] : '';
    }
  },
  actions: {
    /** call endpoint getCommissionRules and save result in store*/
    async getCommissionRules({ commit }) {
      let res = await axiosApiInstance({
        method: 'get',
        url: 'Organizer/ReferralCommission/RulesGet',
        headers: {
          idLang: 1065
        }
      })
      commit("setCommissionRules", res.data.data)
    },
    /** get user log type for fill multiSelect in directory report */
    async getUserLogType({ commit }) {
      let res = await axiosApiInstance({
        method: 'get',
        url: 'Organizer/Enums/GetUserLogType',
        headers: {
          idLang: 1065
        }
      })
      commit("setUserLogType", res.data.data)
    },
    /** get asset log type for fill multiSelect in directory report */
    async getAssetLogType({ commit }) {
      let res = await axiosApiInstance({
        method: 'get',
        url: 'Organizer/User/GetAssetLogReportTypeGet',
        headers: {
          idLang: 1065
        }
      })
      commit("setAssetLogType", res.data.data)
    },

    /** refresh token */
    async refreshToken({ dispatch, commit }) {
      try {


        // request logout
        let refreshToken = getRefreshToken()
        if (refreshToken) {
          setRefreshToken(null)

          commit('setRefreshTokenCode', refreshToken, { root: true })

          let TokenCode = await axiosApiInstance({
            url: 'auth/TokenGet',
            headers: {
              refreshTokenCode: refreshToken,
            },
            method: 'get'
          });


          setToken(TokenCode.data.data.jwtToken)
          setRefreshToken(TokenCode.data.data.refreshTokenCode)

          commit('setRefreshTokenCode', null, { root: true })

        }
        return


      } catch (error) {
        console.log(error)
      }

      dispatch('at/logout')
    }
  },
  modules: {
    at: auth
  },
})