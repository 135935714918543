/**guards  */
import { RedirectIfNotAuthenticated, RedirectIfAuthenticated, clearProfile } from './Guards'

const routes = [
    /**login route */
    {
        path: '/',
        name: 'login',
        component: () => import('../pages/Auth/Login'),
        beforeEnter: RedirectIfAuthenticated,
        meta: {
            title: 'بیت لند | لاگین مدیریت'
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        // beforeEnter: VerifyGuard,
        meta: {
            title: 'بیت لند |  بازگردانی رمز عبور'
        },
        component: () => import('../pages/Auth/ResetPassword'),
    },

    /**dashboard */
    {
        path: '/dashboard',
        component: () => import('../layout/SampleLayout.vue'),
        beforeEnter: RedirectIfNotAuthenticated,
        children: [
            /**dashboard */
            {
                path: '',
                name: 'dashboard',
                meta: {
                    title: 'بیت لند | داشبورد'
                },
                component: () => import('../pages/Dashboard/dashboard/Index')
            },

            /** --------------Start users , means the users  --------------*/
            /**Users list */
            {
                path: 'users',
                name: 'users',
                meta: {
                    title: 'بیت لند | لیست کاربران'
                },
                component: () => import('../pages/Dashboard/users/Index'),
            },

            /**Users Not Verified */
            {
                path: 'users/not-verified',
                name: 'user-not-verified',
                meta: {
                    title: 'بیت لند | کاربران منتظر تائید پروفایل'
                },
                component: () => import('../pages/Dashboard/users/NotVerified')
            },

            /**user not-document-verified */
            {
                path: 'users/not-document-verified',
                name: 'not-document-verified',
                meta: {
                    title: 'بیت لند | کاربران منتظر تائید مدارک'
                },
                component: () => import('../pages/Dashboard/users/NotDocumentVerified')
            },

            /**user detail users/2/personalinfo */
            {
                path: 'users/:id',
                name: 'user-detail-container',
                component: () => import('../pages/Dashboard/users/IndexUserDetailContainer'),
                beforeEnter: clearProfile,
                children: [{
                    path: 'personal-info',
                    name: 'personal-info',
                    meta: {
                        title: 'بیت لند | اطلاعات شخص'
                    },
                    component: () => import('../pages/Dashboard/users/UserPersonalInfo'),
                },
                {
                    path: 'user-log',
                    name: 'user-log',
                    meta: {
                        title: 'بیت لند |  لاگ کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/UserLog'),
                },
                {
                    path: 'history-profile',
                    name: 'history-profile',
                    meta: {
                        title: 'بیت لند | سوابق تغییرات پروفایل'
                    },
                    component: () => import('../pages/Dashboard/users/HistoryProfile'),
                },
                {
                    path: 'asset-log',
                    name: 'asset-log',
                    meta: {
                        title: 'بیت لند | لیست سفارشات'
                    },
                    component: () => import('../pages/Dashboard/users/UserAssetLog'),
                },
                {
                    path: 'user-documents',
                    name: 'user-documents',
                    meta: {
                        title: 'بیت لند | مدارک کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/UserDocuments'),
                },
                {
                    path: 'user-cards',
                    name: 'user-cards',
                    meta: {
                        title: 'بیت لند | حساب های مالی'
                    },
                    component: () => import('../pages/Dashboard/users/UserCards'),
                },
                /**user referrals */
                {
                    path: 'user-referrals',
                    name: 'user-referrals',
                    meta: {
                        title: 'بیت لند | لیست معرف ها'
                    },
                    component: () => import('../pages/Dashboard/users/Referrals'),
                },

                /**user rewards */
                {
                    path: 'user-statistical-reports',
                    name: 'user-statistical',
                    meta: {
                        title: 'بیت لند | گزارشات آماری  '
                    },
                    component: () => import('../pages/Dashboard/users/StatisticalReports'),
                },
                /**user factor */
                {
                    path: 'user-factor',
                    name: 'user-factor',
                    meta: {
                        title: 'بیت لند | فاکتور های واریز و برداشت '
                    },
                    component: () => import('../pages/Dashboard/users/UserFactor'),
                },
                /**user children */
                {
                    path: 'spot',
                    name: 'spot',
                    meta: {
                        title: 'بیت لند | اسپات'
                    },
                    component: () => import('../pages/Dashboard/users/Spot'),
                },
                /**user factor */
                {
                    path: 'digital-coin-request',
                    name: 'digital-coin-request',
                    meta: {
                        title: 'بیت لند | درخواست های ارز دیجیتال '
                    },
                    component: () => import('../pages/Dashboard/users/UserDigitalCoinRequest'),
                },
                /**user asset */
                {
                    path: 'user-asset',
                    name: 'user-asset',
                    meta: {
                        title: 'بیت لند | لیست  دارایی های کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/UserAsset'),
                },
                /**user permission */
                {
                    path: 'permission',
                    name: 'permission',
                    meta: {
                        title: 'بیت لند | سطح دسترسی کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/Permission'),
                },

                /**user children */
                {
                    path: 'user-children',
                    name: 'user-children',
                    meta: {
                        title: 'بیت لند | لیست  زیرمجموعه های کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/UserChildren'),
                },
                /**user informed */
                {
                    path: 'user-informed',
                    name: 'user-informed',
                    meta: {
                        title: 'بیت لند | پیام های دریافتی کاربر'
                    },
                    component: () => import('../pages/Dashboard/users/Informed'),
                },
                ]
            },


            /** -------------- End users  --------------*/

            /** -------------- Start orders  --------------*/
            /**orders */
            {
                path: 'orders',
                name: 'orders',
                meta: {
                    title: 'بیت لند | لیست سفارشات'
                },
                component: () => import('../pages/Dashboard/orders/Index'),
            },

            /* not-verified */
            {
                path: 'orders/not-verified',
                name: 'order-not-verified',
                meta: {
                    title: 'بیت لند | سفارشات باز تائید نشده'
                },
                component: () => import("../pages/Dashboard/orders/NotVerified"),
            },

            /** sell-orders list */
            {
                path: 'orders/sell-orders',
                name: 'sell-orders',
                meta: {
                    title: 'بیت لند | لیست فروش'
                },
                component: () => import('../pages/Dashboard/orders/OrdersSell'),
            },

            /** buy-orders list */
            {
                path: 'orders/buy-orders',
                name: 'buy-orders',
                meta: {
                    title: 'بیت لند | لیست خرید'
                },
                component: () => import('../pages/Dashboard/orders/OrdersBuy'),
            },

            /** duplicate orders list */
            {
                path: 'orders/duplicate',
                name: 'duplicate-orders',
                meta: {
                    title: 'بیت لند | تراکنش های تکراری'
                },
                component: () => import('../pages/Dashboard/orders/DuplicateOrder'),
            },


            /**orders detail orders/2/personalinfo */
            {
                path: 'orders/:id',
                name: 'orders-detail-container',
                component: () => import('../pages/Dashboard/orders/OrderDetailContainer'), children: [
                    /** detail-orders list */
                    {
                        path: 'orders-details',
                        name: 'orders-details',
                        meta: {
                            title: 'بیت لند |  جزئیات سفارشات'
                        },
                        component: () => import('../pages/Dashboard/orders/OrdersDetail'),
                    },
                    /** detail-orders list */
                    {
                        path: 'orders-personal-info',
                        name: 'orders-personal-info',
                        meta: {
                            title: 'بیت لند |  اطلاعات مشتری'
                        },
                        component: () => import('../pages/Dashboard/orders/UserPersonalInfo'),
                    },

                ]
            },


            /** ------------------ End orders  -----------------*/

            /** ----------------Start Payments  ----------------*/
            /**payments */
            {
                path: 'payments',
                name: 'payments',
                meta: {
                    title: 'بیت لند | لیست پرداخت ها'
                },
                component: () => import('../pages/Dashboard/payments/Index'),
            },
            /**payments faild */
            {
                path: 'payments/faild',
                name: 'faild',
                meta: {
                    title: 'بیت لند | لیست پرداخت های ناموفق'
                },
                component: () => import('../pages/Dashboard/payments/Faild'),
            },
            /**ledgers */
            {
                path: 'ledgers',
                name: 'ledgers',
                meta: {
                    title: 'بیت لند | لیست پرداخت های دستی'
                },
                component: () => import('../pages/Dashboard/payments/Ledgers'
                ),
            },

            /** ----------------End Payments ---------------- */

            /** ----------------start carts------------------ */
            /**cards */
            {
                path: 'cards',
                name: 'cards',
                meta: {
                    title: 'بیت لند | لیست کارت ها'
                },
                component: () => import('../pages/Dashboard/cards/Index'),
            },

            /* not-verified cart*/
            {
                path: 'cards/not-verified',
                name: 'cart-not-verified',
                meta: {
                    title: 'بیت لند | کارت های تائید نشده'
                },
                component: () => import('../pages/Dashboard/cards/NotVerified'),
            },

            /**cart detail carts/2/tab1 */
            {
                path: 'carts/:id',
                name: 'carts-detail-container',
                component: () => import('../pages/Dashboard/cards/CartDetailContainer'),
                children: [{
                    path: 'tab1',
                    name: 'tab1',
                    meta: {
                        title: 'بیت لند | تب 1 '
                    },
                    component: () => import('../pages/Dashboard/cards/tab1'),
                },
                {
                    path: 'tab2',
                    name: 'tab2',
                    meta: {
                        title: 'بیت لند | تب 1 '
                    },
                    component: () => import('../pages/Dashboard/cards/tab2'
                    ),
                }
                ]
            },
            /** ----------------End carts------------------ */

            /** ----------------start address------------------ */
            /**addresses */
            {
                path: 'addresses',
                name: 'addresses',
                meta: {
                    title: 'بیت لند | لیست آدرس ها'
                },
                component: () => import('../pages/Dashboard/addresses/Index'),
            },

            /**import */
            {
                path: 'addresses/import',
                name: 'addresses-import',
                meta: {
                    title: 'بیت لند | افزودن گروهی آدرس ها'
                },
                component: () => import('../pages/Dashboard/addresses/Import'),
            },
            /** ----------------End address------------------ */


            /** ----------------start coin------------------ */
            /**coin */
            {
                path: 'coin',
                name: 'coin',
                meta: {
                    title: 'بیت لند | لیست کوین ها'
                },
                component: () => import('../pages/Dashboard/coin/Index'),
            },

            // edit coin
            {
                path: 'coin/edit-coin/:id',
                name: 'edit-coin',
                meta: {
                    title: 'بیت لند |ویرایش کوین '
                },
                component: () => import('../pages/Dashboard/coin/AddAndEdit'),
            },
            /** ----------------End coin------------------ */

            /** ----------------start market------------------ */
            /**market */
            {
                path: 'market',
                name: 'market',
                meta: {
                    title: 'بیت لند | لیست بازار ها'
                },
                component: () => import('../pages/Dashboard/Market/Index'),
            },
            // add market
            {
                path: 'market/add-market',
                name: 'add-market',
                meta: {
                    title: 'بیت لند | افزودن بازار '
                },
                component: () => import('../pages/Dashboard/Market/AddAndEdit'),
            },
            // edit market
            {
                path: 'market/edit-market/:id',
                name: 'edit-market',
                meta: {
                    title: 'بیت لند |ویرایش کوین '
                },
                component: () => import('../pages/Dashboard/Market/AddAndEdit'),
            },
            /** ----------------End market------------------ */

            /** ----------------Start referrals------------------ */
            /**referrals */
            {
                path: 'referrals',
                name: 'referrals',
                meta: {
                    title: 'بیت لند | گزارش روزانه'
                },
                component: () => import('../pages/Dashboard/referrals/Index'),
            },

            /**rewards */
            {
                path: 'rewards',
                name: 'rewards',
                meta: {
                    title: 'بیت لند | پاداش ها'
                },
                component: () => import('../pages/Dashboard/referrals/Rewards'),
            },
            /** ----------------End referrals------------------ */
            /** ----------------Start CommissionRules------------------ */
            /**CommissionRules */
            {
                path: 'commission-rules',
                name: 'commission-rules',
                meta: {
                    title: 'بیت لند |  قوانین کمیسیون '
                },
                component: () => import('../pages/Dashboard/CommissionRules/Index'),
            },
            // edit CommissionRules
            {
                path: 'commission/edi-commission/:id',
                name: 'edit-commission',
                meta: {
                    title: 'بیت لند |ویرایش کوین '
                },
                component: () => import('../pages/Dashboard/CommissionRules/AddEditCommission'),
            },
            // users
            {
                path: 'commission-rules/:id/users',
                name: 'users-commission',
                meta: {
                    title: 'بیت لند |کاربران قوانین کمیسیون  '
                },
                component: () => import('../pages/Dashboard/CommissionRules/Users'),
            },
            /** ----------------End CommissionRules------------------ */
            /** ----------------Start profile------------------ */
            /**profile */
            {
                path: 'profile',
                name: 'profile',
                meta: {
                    title: 'بیت لند |  پروفایل  '
                },
                component: () => import('../pages/Dashboard/profile/index'),
            },
            /** ----------------End prize------------------ */
            /** ----------------Start prize------------------ */
            /**prize */
            {
                path: 'prize',
                name: 'prize',
                meta: {
                    title: 'بیت لند |  جوایز مدیریتی  '
                },
                component: () => import('../pages/Dashboard/prize/Index'),
            },
            /**add prize */
            {
                path: 'prize/add-prize',
                name: 'prize-edit',
                meta: {
                    title: 'بیت لند | افزودن جوایز   '
                },
                component: () => import('../pages/Dashboard/prize/AddAndEdit'),
            },
            // edit prize
            {
                path: 'prize/:id/edit',
                name: 'edit-prize',
                meta: {
                    title: 'بیت لند | ویرایش جوایز '
                },
                component: () => import('../pages/Dashboard/prize/AddAndEdit'),
            },
            // AllocatedListGet prize
            {
                path: 'prize/:id/Allocated',
                name: 'Allocated-prize',
                meta: {
                    title: 'بیت لند | تعداد اختصاص  '
                },
                component: () => import('../pages/Dashboard/prize/Allocated'),
            },
            /** ----------------End prize------------------ */
            /** ----------------Start tarder-level------------------ */
            /**tarder-level */
            {
                path: 'trader-level',
                name: 'trader-level',
                meta: {
                    title: 'بیت لند |  سطح کاربری  '
                },
                component: () => import('../pages/Dashboard/traderLevel/Index'),
            },
            // edit CommissionRules
            {
                path: 'trader-level/:id/edit',
                name: 'edit-trader-level',
                meta: {
                    title: 'بیت لند |ویرایش سطح کاربری '
                },
                component: () => import('../pages/Dashboard/traderLevel/Edit'),
            },
            // users tarde level
            {
                path: 'trader-level/:id/users',
                name: 'users-trader-level',
                meta: {
                    title: 'بیت لند |لیست کاربران '
                },
                component: () => import('../pages/Dashboard/traderLevel/Users'),
            },
            /** ----------------End tarder-level------------------ */
            /** ----------------Start informer------------------ */
            /**informer */
            {
                path: 'informer',
                name: 'informer',
                meta: {
                    title: 'بیت لند | اطلاع رسانی  '
                },
                component: () => import('../pages/Dashboard/informer/Index'),
            },
            // edit CommissionRules
            {
                path: 'informer/add',
                name: 'edit-informer',
                meta: {
                    title: 'بیت لند | اطلاع رسانی  '
                },
                component: () => import('../pages/Dashboard/informer/AddAndEdit'),
            },
            /** ----------------End informer------------------ */

            /** ----------------Start Reports------------------ */
            /**asset */
            {
                path: 'reports/asset',
                name: 'asset-report',
                meta: {
                    title: 'بیت لند | گزارش دارایی  '
                },
                component: () => import('../pages/Dashboard/reports/Assets'),
            },

            /**asset-log */
            {
                path: 'reports/digital-coin',
                name: 'digital-coin',
                meta: {
                    title: 'بیت لند | درخواست های ارز دیجیتال  '
                },
                component: () => import('../pages/Dashboard/reports/DigitalCoinRequest'),
            },
            /**asset-log */
            {
                path: 'reports/asset-log',
                name: 'asset-log-report',
                meta: {
                    title: 'بیت لند | گزارش دارایی  '
                },
                component: () => import('../pages/Dashboard/reports/AssetLog'),
            },
            /**market-spot-stats */
            {
                path: 'reports/spot',
                name: 'spot-report',
                meta: {
                    title: 'بیت لند | اسپات'
                },
                component: () => import('../pages/Dashboard/reports/Spot'),
            },
            /**user-logs */
            {
                path: 'reports/user-logs',
                name: 'user-logs-report',
                meta: {
                    title: 'بیت لند |  گزارش های کاربر'
                },
                component: () => import('../pages/Dashboard/reports/UserLog'),
            },
            /**user-report for trade and gift and etc */
            {
                path: 'reports/statistical-reports',
                name: 'user-trade-report',
                meta: {
                    title: 'بیت لند |  گزارشات آماری  '
                },
                component: () => import('../pages/Dashboard/reports/StatisticalReports'),
            },

            /** ----------------End Reports------------------ */

            /** ----------------Start factor------------------ */

            /**factor deposit */
            {
                path: 'factor/deposit',
                name: 'deposit-factor',
                meta: {
                    title: 'بیت لند |  فاکتور واریز   '
                },
                component: () => import('../pages/Dashboard/factors/Deposit'),
            },
            /**factor withdraw */
            {
                path: 'factor/withdraw',
                name: 'withdraw-factor',
                meta: {
                    title: 'بیت لند |  فاکتور برداشت   '
                },
                component: () => import('../pages/Dashboard/factors/Withdraw'),
            },
            /**factor deposit-tmn */
            {
                path: 'factor/deposit-tmn',
                name: 'deposit-tmn-factor',
                meta: {
                    title: 'بیت لند |  فاکتور واریز تومانی'
                },
                component: () => import('../pages/Dashboard/factors/DepositTmn'),
            },
            /**factor withdraw-tmn */
            {
                path: 'factor/withdraw-tmn',
                name: 'withdraw-tmn-factor',
                meta: {
                    title: 'بیت لند |  فاکتور برداشت تومانی'
                },
                component: () => import('../pages/Dashboard/factors/WithdrawTmn'),
            },
            /**factor  DepositClaim*/
            {
                path: 'factor/deposit-claim',
                name: 'deposit-claim',
                meta: {
                    title: 'بیت لند | ادعای واریز'
                },
                component: () => import('../pages/Dashboard/factors/DepositClaim'),
            },




            /** ----------------End factor------------------ */

            /** ----------------Start network------------------ */
            /**network */
            {
                path: 'network',
                name: 'network',
                meta: {
                    title: 'بیت لند |  شبکه  '
                },
                component: () => import('../pages/Dashboard/network/Index'),
            },
            // edit CommissionRules
            {
                path: 'network/:id/edit',
                name: 'edit-network',
                meta: {
                    title: 'بیت لند | اطلاع رسانی  '
                },
                component: () => import('../pages/Dashboard/network/AddAndEdit'),
            },
            /** ----------------End network------------------ */

            /** ----------------Start Docs Reject Reason------------------ */
            /**Docs Reject Reason */
            {
                path: 'reject-reason',
                name: 'reject-reason',
                meta: {
                    title: 'بیت لند |  دلایل رد مستندات  '
                },
                component: () => import('../pages/Dashboard/docsRejectReason/Index'),
            },
            // edit RejectReason
            {
                path: 'reject-reason/:id/edit',
                name: 'edit-reject-reason',
                meta: {
                    title: 'بیت لند | ویرایش دلایل رد مستندات  '
                },
                component: () => import('../pages/Dashboard/docsRejectReason/AddAndEdit'),
            },
            // add RejectReason
            {
                path: 'reject-reason/add',
                name: 'add-reject-reason',
                meta: {
                    title: 'بیت لند | افزودن دلایل رد مستندات  '
                },
                component: () => import('../pages/Dashboard/docsRejectReason/AddAndEdit'),
            },
            /** ----------------End Docs Reject Reason------------------ */
            /** ----------------Start Docs Notification------------------ */
            /**Docs Notification */
            {
                path: 'notification',
                name: 'notification',
                meta: {
                    title: 'بیت لند |  اعلانات  '
                },
                component: () => import('../pages/Dashboard/notification/Index'),
            },
            // edit Notification
            {
                path: 'notification/:id/edit',
                name: 'edit-notification',
                meta: {
                    title: 'بیت لند | ویرایش اعلانات  '
                },
                component: () => import('../pages/Dashboard/notification/AddAndEdit'),
            },
            // add Notification
            {
                path: 'notification/add',
                name: 'add-notification',
                meta: {
                    title: 'بیت لند | افزودن اعلانات  '
                },
                component: () => import('../pages/Dashboard/notification/AddAndEdit'),
            },
            // user set Notification
            {
                path: 'notification/:id/user-set',
                name: 'user-set-notification',
                meta: {
                    title: 'بیت لند | اعمال اعلانات برای کاربران  '
                },
                component: () => import('../pages/Dashboard/notification/UserSet'),
            },
            /** ----------------End Docs Notification------------------ */
            /**-----------------change user asset ----------------------*/
            {
                path: 'change-asset/:id/:type',
                name: 'change-asset',
                meta: {
                    title: 'بیت لند | تغییر دارایی های کاربر'
                },
                component: () => import('../pages/Dashboard/users/ChangeAsset'),
            },
        ]
    }
]

export default routes