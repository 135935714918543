/* eslint-disable */
import Vue from 'vue'
import store from "../store/index"
import { settings } from "./Settings.js"
import { getToken } from "./AuthApi"
/**the beautiful nprogress */
import NProgress from 'vue-nprogress'
const nprogress = new NProgress()

import jwt_decode from "jwt-decode"


/** 
 * * HEADS UP 
 * * axiosApiInstance
 * */

/** ----------------  Start axiosApiInstance ---------------- */
const axios = require('axios')
const axiosApiInstance = axios.create()
axiosApiInstance.defaults.baseURL = settings.apiV1
axiosApiInstance.defaults.method = "post" // * make default method post

// Add a request interceptor
axiosApiInstance.interceptors.request.use(function (config) {
    /**show nprogress */
    nprogress.done()
    nprogress.start()
    nprogress.inc(0.3)

    // Get token and add it to header "Authorization"
    const token = getToken()
    if (token) {
        config.headers.Authorization = "Bearer " + token


        // refresh token
        var decoded = jwt_decode(token)
        let time = Date.now()
        time = time + (14 * 60000)
        // console.log("time refresh token + 15 min: " + time)
        // console.log('time  decoded.exp : ' + decoded.exp * 1000)
        // console.log('state time+15min >=: ecpired' + time >= (decoded.exp * 1000))

        if (time >= decoded.exp * 1000 && !store.state.refreshTokenCode) {
            store.dispatch('refreshToken')
            console.log("set refresh token")
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    nprogress.done()
    return Promise.reject(error)
});


// Add a response interceptor
axiosApiInstance.interceptors.response.use(function (response) {
    //  We don't need to Do something with response data yet!
    nprogress.done()
    return response
}, function (error) {
    nprogress.done()

    if (error.response) {
        /**handle errors */
        switch (error.response.status) {
            case 400:

                let code = error.response.data.code

                // Empty table
                if (code == -1000200) {
                    break
                }

                // show log for user
                if (code == -1) {
                    console.log(error.response.data.message)
                    break
                }

                /** invalid token */
                if (code == -2000150 || code == -2000123) {
                    // showNotifyError('Dev Mode - دلیل خروج کد :' + code)
                    store.dispatch('at/logout')
                    break
                }

                showNotifyError(error.response.data.message)
                break
            case 401:
                store.dispatch('at/logout')
                break
            case 403:
                /**
                 * the user token is expired
                 * remove the token and show the login
                 */
                // showNotifyError(settings.translate.error403)
                store.dispatch('at/logout')
                break
            case 404:
                showNotifyError(settings.translate.error404)
                break
            case 422:
                /**we have array errors */
                showNotifyError422(error.response.data.errors)
                break
            case 429:
                console.log(error.response.data.message)
                break
            case 500:
                showNotifyError(settings.translate.error500)
                break
            case 502:
                showNotifyError(settings.translate.error502)
                break
            default:
                showNotifyError(settings.translate.errorUnknown)
        }
    }

    return Promise.reject(error);
});

/**show error notification
 * @param string error the error code
 */
function showNotifyError(error) {
    Vue.notify({
        text: error,
        type: "error"
    })
}

/**show error notification for 422 error
 * @param array|object errors
 */
function showNotifyError422(errors) {
    let err
    if (typeof errors === 'object') {
        err = errors
    }
    if (typeof errors === 'array') {
        err = errors[0]
    }
    let msg = Object.values(err)[0]

    if (errors.length === 1) {
        Vue.notify({
            text: errors[0],
            type: 'error'
        })
        return
    }
    Vue.notify({
        text: msg[0],
        type: "error"
    })
}
/** ----------------  End axiosApiInstance ---------------- */

export { axiosApiInstance }