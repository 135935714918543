<template>
  <div class="sidebar">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo" @click="dashboard">
        <a href="" aria-label="sidebar mini logo" class="simple-text logo-mini">
          <div class="logo-img" :class="{ 'logo-img-rtl': $rtl.isRTL }">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="40" viewBox="0 0 32.062 46.469">
              <g id="Group_745" data-name="Group 745" transform="translate(-509.893 -437.461)">
                <path
                  id="Path_1777"
                  data-name="Path 1777"
                  d="M864.739,457.912a7.585,7.585,0,0,0-4.6-3.15,12.585,12.585,0,0,0,4.833-3.046,8.326,8.326,0,0,0,2.321-4.518q.789-4.831-2.091-7.282t-9.362-2.455H842.8l-5.961,36.512h13.83c.1,0,.2,0,.3,0l.644-3.945c-.065,0-.128,0-.192,0h-8.68l2.146-13.144h8.78q7.834,0,6.76,6.607a7.085,7.085,0,0,1-2.917,4.8,7.748,7.748,0,0,1-.946.587l-.742,4.548a14.418,14.418,0,0,0,5.151-2.264,11.342,11.342,0,0,0,4.744-7.714A7.587,7.587,0,0,0,864.739,457.912Zm-5.493-6.453a10.123,10.123,0,0,1-5.8,1.577h-7.939l1.895-11.6h7.784q3.91,0,5.61,1.365t1.214,4.377A6.2,6.2,0,0,1,859.246,451.458Z"
                  transform="translate(-326.942)"
                  fill="#fff"
                />
                <path id="Path_1778" data-name="Path 1778" d="M867.026,503.487h18.913l-.644,3.945H861.1l5.96-36.509h5.283Z" transform="translate(-343.984 -23.502)" fill="#fff" />
              </g>
            </svg>
          </div>
        </a>
        <a href="" class="simple-text logo-normal">
          {{ title }}
        </a>
      </div>
      <slot> </slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link v-for="(link, index) in sidebarLinks" :key="index" :to="link.path" :name="link.name" :icon="link.icon"> </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink";

export default {
  props: {
    title: {
      type: String,
      default: "بیت لند"
    },
    backgroundColor: {
      type: String,
      default: "vue"
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    };
  },
  components: {
    SidebarLink
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
    shortTitle() {
      return this.title.split(' ')
        .map(word => word.charAt(0))
        .join('').toUpperCase();
    }
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: []
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
    dashboard() {
      this.$router.push({ path: "/", replace: true })
    }
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true
    });
  }
}
</script>

<style>
.logo {
  cursor: pointer;
}
</style>
