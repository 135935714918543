import jwt_decode from "jwt-decode"

/**
 * auth api functions
 */
const db = localStorage

/**
 * set the auth token
 */
function setToken(token) {
    db.setItem('admin_token', token)
}

/**
 * get the auth token
 */
function getToken() {
    return db.getItem('admin_token')
}

/**
 * set the refresh token
 */
function setRefreshToken(token) {
    db.setItem('admin_refreshToken', token)
}

/**
 * get the refresh token
 */
function getRefreshToken() {
    return db.getItem('admin_refreshToken')
}

/**
 * remove the auth token
 */
function removeToken() {
    db.removeItem('admin_token')
    db.removeItem('admin_refreshToken')
}



/** 2FA */

/**
 * set the 2FA
 */
function set2FA(state) {
    db.setItem('loginVia2FA', state)
}

/**
 * get the 2FA
 */
function get2FA() {
    return db.getItem('loginVia2FA')
}

/**
 * remove the 2FA
 */
function remove2FA() {
    db.removeItem('loginVia2FA')
}


/**
 * check user authenticated or not
 * @returns bool 
 */
function authenticated() {

    /**
     * the token in sending request if expired will be removed and redirected to login
     * so if we have token , means we have authenticated
     */
    if (getToken()) {
        if (!isTokenExpired()) {
            return true
        }
    }

    /**we have not token or token is expired */
    return false
}

/**
 * check session expired or not 
 * @return boolean , true => jwt expierd , false => jwt not expired
 * */
function isTokenExpired() {
    var token = getToken()
    var decoded = jwt_decode(token)
    return Date.now() >= decoded.exp * 1000
}

export {
    set2FA,
    get2FA,
    remove2FA,

    setToken,
    getToken,
    removeToken,
    authenticated,
    getRefreshToken,
    setRefreshToken
}