import Vue from "vue"
import VueRouter from "vue-router"
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App"
import BlackDashboard from "./plugins/blackDashboard"
import i18n from "./i18n"
Vue.use(BlackDashboard)
Vue.use(VueRouter)
Vue.use(RouterPrefetch)

/**bitland dashboard */
import router from "./router"
import store from "./store"
import { authenticated } from "./settings/AuthApi"

/**import general styles */
import "./assets/sass/general.scss"

/**recaptcha v3 */
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: window.env.VUE_APP_RECAPTCHA_SITE_KEY, loaderOptions: { autoHideBadge: true } })

/**the beautiful nprogress */
import NProgress from 'vue-nprogress'
const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
}
Vue.use(NProgress, options)
const nprogress = new NProgress()

/**axios for global ajax calls */
import { axiosApiInstance } from "./settings/Axios.js"
Vue.prototype.$axios = axiosApiInstance

/**ignore production tip */
Vue.config.productionTip = false

/**vue notification */
import Notifications from 'vue-notification'
Vue.use(Notifications)

/**vue confirm */
import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

/** component log-state for show or hide console.log */
import logState from "./components/LogState"

import './registerServiceWorker'

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  nprogress,
  mounted() {
    logState();
    /**set user authentications status */
    store.commit('at/setAuthenticated', authenticated())
  },
  render: h => h(App)
}).$mount("#app")