/** hide or show console.log  */
function logState() {
    if (process.env.VUE_APP_DEBUG == 'false') {
        console.log = function () { };
        console.error = function () { };
        console.warn = function () { };
        console.clear = function () { };
        console.time = function () { };
        console.timeLog = function () { };
        console.timeEnd = function () { };
        console.table = function () { };
        console.count = function () { };
        console.group = function () { };
        console.groupEnd = function () { };
        console.debug = function () { };
    }
}
export default logState;